import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { SelectChangeEvent } from '@mui/material/Select';
import { getStorageData, removeStorageData} from "../../../framework/src/Utilities";
interface ApiData {
  contentType?: string,
  method?: string,
  endPoint?: string,
  body?: {},
  type?: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  categoryData: any,
  allFilter: any,
  navigation?:any,
  id:any,
  filterData: any,
  passDataToFilter: any,
  categoryId: any,
  categoryName: string;
  handleShowCategory?:any
  handleCategories?:any
  openLoginModal?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  product: any;
  sortOption: string;
  filterBackendError: string;
  sortingByWomen: boolean;
  sortingByMen: boolean;
  filterOpen: boolean;
  productCount: number;
  category_id:number|'',
  categoryName:string|any;
  openLoginModal:boolean;
  cartLength:number;
  categoriesArray:any[];
  categoriesOnHoverList:any[];
  showCategoryPage:boolean;
  filteredBrands:any[];
  searchTerm:string;
  brand:any;
  allFilter:boolean;
  selectedCategoryList:any[];
  year:string,
  color:string;
  size:any;
  datePosted:any;
  subCategory:any[];
  priceRange:any[];
  minPriceData: number,
  maxPriceData: number,
  productionYearData: any[],
  selectColorData:any;
  sizeData:any;
  categoriesFilterOptions:any[];
  brandData:any,
  subCategoryData: any,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CategoriesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  productApiCallId: string = "";
  addToFavoriteApi?: string = "";
  getHoverCategoriesList:string="";
  getCategoriesListApiCallId:string="";
  filterApplyApiId:string="";
  getFilterOptionsAPi:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      product: [],
      sortOption: 'recent',
      filterBackendError: '',
      sortingByWomen: false,
      sortingByMen: false,
      filterOpen: false,
      productCount: 0,
      category_id:'',
      categoryName:"",
      openLoginModal:false,
      cartLength:0,
      categoriesArray:[],
      categoriesOnHoverList:[],
      showCategoryPage:false,
      filteredBrands:[],
      searchTerm:"",
      brand:"",
      allFilter:false,
      selectedCategoryList:[],
      year:"",
      color:"",
      size:"",
      datePosted:"",
      subCategory:[],
      minPriceData: 0,
      maxPriceData: 0,
      priceRange: [50,450],
      productionYearData: [],
      selectColorData:[],
      sizeData:[],
      categoriesFilterOptions:[],
      brandData: [],
      subCategoryData: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && !responseJson?.errors) {
        if(apiRequestCallId === this.productApiCallId){
          this.handleProductApiCallId(responseJson)
        }else if(apiRequestCallId===this.getHoverCategoriesList){
        this.setState({ categoriesOnHoverList: responseJson });
        }else if(apiRequestCallId===this.getCategoriesListApiCallId){
        this.setState({ categoriesArray: responseJson.data });

        }else if(apiRequestCallId===this.addToFavoriteApi){
          this.handleApplyButton()

        }else if(apiRequestCallId===this.getFilterOptionsAPi){
          let minPriceFloor1= Math.floor(responseJson.min_price)
          let maxPriceFloor1 = Math.floor(responseJson.max_price)
          this.setState({
            priceRange: [minPriceFloor1, maxPriceFloor1],
            brandData: responseJson.brands,
            subCategoryData: responseJson.sub_categories,
            productionYearData: responseJson.production_year,
            selectColorData: responseJson.color_codes,
            sizeData: responseJson.size_names,
            minPriceData: minPriceFloor1,
            maxPriceData: maxPriceFloor1,
            filteredBrands: responseJson.brands,
            categoriesFilterOptions:responseJson.categories
          })
        }else if(apiRequestCallId===this.filterApplyApiId){
            this.setState({allFilter:false,product:responseJson.data})
        }

      
       
      }
      this.handleErrResponse(responseJson,apiRequestCallId)
     
    }
  }
  handleCategoryData = async(categoryName: any, categoryId: any) => {
    this.props.navigation.navigate("CategoriesWeb",{categoryId,categoryName});
  
  }
  navigateTobrandlist=()=>{
    this.props.navigation.navigate("BrandWeb")
  }
  apiCall = async (token:any,data: ApiData) => {
    const { contentType, method, endPoint, body } = data
    const header = {
      'Content-Type': contentType,
      token:token||""
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  async componentDidMount() {
    const { categoryId } = this.props;
    const categoryName=new URLSearchParams(window.location.search).get('categoryName')
    this.setState({categoryName:categoryName})
    const categoryIdParams=new URLSearchParams(window.location.search).get('categoryId')
    this.handleProduct(categoryIdParams)
    this.getCategoriesList()
  }
  
  async componentDidUpdate(prevProps: any) {
    const { filterData, categoryId } = this.props;

    if (prevProps.filterData !== filterData || prevProps.categoryId !== categoryId) {
      const filterCategoryId = filterData?.categoryId || categoryId;
  
      if (filterCategoryId !== prevProps.categoryId || filterData !== prevProps.filterData) {
        await this.handleProduct({ ...filterData, categoryId: filterCategoryId });
      }
    }
  }

  navigateToDetailedProductView=(productId:string)=>{
    this.props.navigation.navigate("ProductDescription",{productId:productId,path:{productId:productId}})
  }
  getCategoriesList=async()=>{
    const token=await getStorageData("loginToken")
    this.getCategoriesListApiCallId= await this.apiCall(token,{
      method:"GET",
      endPoint:"/bx_block_categories/categories"
  })
  }
  handleShowCategory=()=>{
    this.setState({showCategoryPage:false},()=>{
      this.handleApplyButton()
    })
  }
  openLoginModal=()=>{
    this.setState({openLoginModal:true})
  }

  handleCategoryDataSearch = (categoryData: any, categoryId: any) => {
    this.setState({ product:categoryData, category_id:categoryId });
  };
  getCategoriesOnHover=async(categorID:string|number)=>{
    const token=await getStorageData("loginToken")
    if(categorID!=0){
      this.getHoverCategoriesList=await this.apiCall(token,{
        method: configJSON.httpGetType,
        endPoint:`/bx_block_categories/categories/categories_hover_options?category_id=${categorID}`
      });
    }
  }
  handleSubCategorySelect = (subCat: any) => {
    const { subCategory } = this.state;
    this.setState({
      subCategory: subCategory.includes(subCat)
        ? subCategory.filter((name:string) => name !== subCat)
        : [...subCategory, subCat],
    });
  };
  handleProductApiCallId = (responseJson: any) => {
    if(responseJson && responseJson.data){
      this.setState({
        product: responseJson.data,
        productCount: responseJson.meta.total_count,
      })
    }
    if(responseJson && responseJson.errors){
      this.setState({product: [], filterBackendError: responseJson.errors[0].message})
    }
  }

  handleProduct = async (data: any) => {
    const { sortingByWomen, sortingByMen } = this.state;
    const categoryIdParams=new URLSearchParams(window.location.search).get('categoryId')

    const queryParamsObj: any = { category_id: categoryIdParams||this.props.categoryId };
    
    const filterConditions = [
      { key: 'sorted_by_women', condition: data.sortByWomen || sortingByWomen, value: 'true' },
      { key: 'sorted_by_men', condition: data.sortByMen || sortingByMen, value: 'true' },
      { key: 'sort_by', condition: data.sortByProductionYear, value: 'production_year' },
      { key: 'sort_by', condition: data.sortByHighToLow, value: 'price_high_to_low' },
      { key: 'sort_by', condition: data.sortByLowToHigh, value: 'price_low_to_high' },
      { key: 'filter_by_price_ranges', condition: data.priceRange?.length > 0, value: data.priceRange?.join('..') },
      { key: 'filter_by_brand_ids', condition: data.brand && data.brand !== "", value: data.brand },
      { key: 'filter_by_subcategory_ids', condition: data.subCategory?.length > 0, value: data.subCategory?.join(',') },
      { key: 'filter_by_product_year_production', condition: data.productionYear && data.productionYear !== "", value: data.productionYear },
      { key: 'sort_by', condition: data.datePosted && data.datePosted !== "", value: data.datePosted },
      { key: 'filter_by_color_ids', condition: data.color && data.color !== "", value: data.color },
      { key: 'filter_by_size_ids', condition: data.size && data.size !== "", value: data.size },
    ];
  
    filterConditions.forEach(({ key, condition, value }) => {
      if (condition) {
        queryParamsObj[key] = value;
      }
    });
  
    const queryParams = new URLSearchParams(queryParamsObj).toString();
    const token=await getStorageData("loginToken")
  
    this.productApiCallId = await this.apiCall(token,{
      method: configJSON.httpGetType,
      endPoint: `${configJSON.categoriesEndPoint}?${queryParams}`,
    });
  };  

  handleSortOption = (event: SelectChangeEvent) => {  
    this.setState({ sortOption:event.target.value }, this.handleApplyButton);
};
  
  handleWomenFilter = () => {
    this.setState({sortingByWomen:!this.state.sortingByWomen},this.handleApplyButton)
  };


  handleMenFilter = () => {
    this.setState({sortingByMen:!this.state.sortingByMen},this.handleApplyButton)
  }
  closeLoginModal=()=>{
    this.setState({openLoginModal:false})
  }

  handleProductionYearFilter = () => {
    this.handleProduct({ ...this.props.filterData, sortByProductionYear: true })
  }

  handlePriceHighToLow = () => {
    this.handleProduct({ ...this.props.filterData, sortByHighToLow: true })
  }

  handlePriceLowToHigh = () => {
    this.handleProduct({ ...this.props.filterData, sortByLowToHigh: true })
  }

  handleAddToFavorite=async(catalogue_variant_id:number,category_id:number)=>{
    this.setState({category_id:category_id})
    const bodyFav = {
      "wishlist": {
        "catalogue_variant_id": catalogue_variant_id
      }
    }
    const tokenIs= await getStorageData('loginToken')
    const headerIs = {
      "Content-Type": configJSON.categoryApiContentType,
       ...(tokenIs&&{token: tokenIs})
    };
    const requestMessageAddToFavorite = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addToFavoriteApi = requestMessageAddToFavorite.messageId;
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.moveToFavorite
    );
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerIs)
    );
    requestMessageAddToFavorite.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyFav)
    );
    runEngine.sendMessage(requestMessageAddToFavorite.id, requestMessageAddToFavorite);
  

  }

  handleErrResponse =(responseJson:{errors:[any]},apiRequestCallId:string)=>{
    if(responseJson && responseJson?.errors){
      if(responseJson?.errors[0]?.message){
        this.setState({product:[],allFilter:false})
      }
      if(apiRequestCallId === this.addToFavoriteApi){
        if (responseJson?.errors[0]?.token)
          removeStorageData('loginToken')
        this.openLoginModal()
      }
    }
    if(responseJson && responseJson?.errors){
      this.setState({product: this.state.product.length === 0})
    }
  }
  handleSearchChange = (newInputValue: string) => {
    this.setState({ searchTerm: newInputValue });
  };
  handleSelectBrand = (selectedBrand: any) => {
    this.setState({ 
      searchTerm: selectedBrand ? selectedBrand.name : '',
      brand: selectedBrand ? selectedBrand.id : '' 
    });
  };
  handleCloseAllFilter = () => {
    this.setState({allFilter: false})
  }
  handleClearAllBtn = () => {
    this.setState({allFilter:false,selectedCategoryList:[],year:"",color:"",
      size:"",
      datePosted:"",
      subCategory:[],
      filteredBrands:[],
      searchTerm:"",
      brand:0,
      sortingByMen:false,
      sortingByWomen:false
    },()=>this.handleApplyButton())
    
        this.handleCloseAllFilter()
    
      }
      renderValueLabel = (value: number) => {
        return `$${value}`;
      };
      handlePriceChange = (event: Event, newValue: number | number[]) => {
        if (Array.isArray(newValue) && newValue.length === 2) {
          this.setState({ priceRange: newValue });
        }
      };
      handleApplyButton =async () => {
        const endPointWithFilters=this.getApplyFilterParams();
        const token=await getStorageData("loginToken")
        this.filterApplyApiId=await this.apiCall(token,{
          method:"GET",
          endPoint:endPointWithFilters
        })
      }
      getApplyFilterParams = () => {
        const filterParams=this.getFilterParams();
        const endpoint=this.getEndpoint()
      
        return `${endpoint}&&${filterParams}`;
      };
      getEndpoint = () => {
        const categoryId=new URLSearchParams(window.location.search).get('categoryId')
       const endpoint=`/bx_block_catalogue/catalogues/filtered_catalogues?category_id=${categoryId}`
          return endpoint
      };
      getFilterParams = () => {
      
        const { selectedCategoryList, sortOption, sortingByWomen, sortingByMen, brand, subCategory, year, color, size } = this.state;
        const params:any = {};
      
        if (sortOption) params.sort_by = sortOption;
        if (sortingByWomen) params.sorted_by_women = sortingByWomen;
        if (sortingByMen) params.sorted_by_men = sortingByMen;
        if (brand) params.filter_by_brand_ids = brand;
        if (subCategory) params.filter_by_subcategory_ids = subCategory;
        if (year) params.filter_by_product_year_production = year;
        if (color) params.filter_by_color_ids = color;
        if (size) params.filter_by_size_ids = size;
        if (this.state.selectedCategoryList) params.filter_by_category_ids=selectedCategoryList?.length ? selectedCategoryList.join(",") : null
        return this.buildParams(params);
      };
      buildParams = (paramsObj:any) => {
        const params = new URLSearchParams();
        Object.entries(paramsObj).forEach(([key, value]:any) => {
          if (value) {
            params.append(key, value);
          }
        });
        return decodeURIComponent(params.toString());
      };
      handleCategorSelect=(cat:any)=>{
        const { selectedCategoryList } = this.state;
        this.setState({
          selectedCategoryList: selectedCategoryList.includes(cat)
            ? selectedCategoryList.filter((name:any) => name !== cat)
            : [...selectedCategoryList, cat],
        });
      }
      handleOpenAllFilter=()=>{
        this.setState({allFilter: true},()=>{
          this.handleFilterData()
        });
      }
      handleFilterData = async () => {
    const categoryId=new URLSearchParams(window.location.search).get('categoryId')

        const tokenIs= await getStorageData('loginToken')
        this.getFilterOptionsAPi=await this.apiCall(tokenIs,{
          method:"GET",
          endPoint:`/bx_block_categories/categories/filter_view?category_id=${categoryId}`,
      })
      }
  // Customizable Area End
}